import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["currentSkipStripe", "planSelectBox", "addonsSelect", "addonsDiv", "hasPaymentMethod", "currencySelectBox",
                    "roleWarning", "skipStripe", "rolePicker", "expiryDate", "enterpriseFields", "enterpriseWarning",
                    "clinicSubscription", "clinicFields", "skipStripeHidden", "maxStorage", "storageAddon", "objectId", "checkStorageResult", "checkAddonStorageResult"];

  static autoSelectedModules = [];
  connect() {
    this.saveBtn = document.querySelector("input[type='submit']");
    if (this.hasAddonsSelectTarget) {
      $(this.addonsSelectTarget).on("select2:select select2:unselect", this.checkAddonsSelection.bind(this));
    }

    if (this.hasSkipStripeHiddenTarget) {
      this.skipStripeHiddenTarget.value = this.skipStripeTarget.value; // align values regardless clinic or personal subscription
    }
    if (this.hasClinicSubscriptionTarget) {
      if (this.planSelectBoxTarget.value == "enterprise") {
        // unhide fields
        this.enterpriseFieldsTarget.classList.remove("hidden");
        // disable skip stripe -> change to Yes always
        this.skipStripeTarget.value = true;
        this.disableElement(this.skipStripeTarget);
      }
    }

    if (this.hasHasPaymentMethodTarget && this.hasCurrentSkipStripeTarget) {
      // user pays to Stripe (currentSkipStripe => false) and has no payment method then block the plan selection
      // if admin forces to change, default to Trial
      if (this.hasPaymentMethodTarget.value === "false" && this.currentSkipStripeTarget.value === "false") {
        this.setDefaultOption();
      }
      // cannot change currency for existing pay-to-Stripe customers (with payment method)
      if (this.existingStripeUser()) this.disableElement(this.currencySelectBoxTarget);
      // show expiry date field if skip stripe
      if (this.skipStripeTarget.value === "true") this.expiryDateTarget.classList.remove("hidden");
    }

    if (this.hasRolePickerTarget) {
      var defaultRoleId = document.querySelector("#user_clinic_role").options[0].value; // select first option always
      this.checkRoleEligibility(defaultRoleId);
    }

    const dateInput = document.getElementById("subscription_cancel_at_3i");
    const monthInput = document.getElementById("subscription_cancel_at_2i");
    const yearInput = document.getElementById("subscription_cancel_at_1i");
    if (monthInput !== undefined) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      this.adjustMonthOptions(monthInput, tomorrow);
    }
    this.getDaysInMonth(dateInput, yearInput.value, monthInput.value);

    this.maxStorageInputTimer = null;

    if (this.hasAddonsSelectTarget && this.hasAddonsDivTarget) {
      this.handlePlanChange();
      this.checkAddonsSelection();
    }
  }

  changeSkipStripe(event) {
    event.preventDefault();
    var skipStripeSelectBox = event.target.options[event.target.selectedIndex].value; // selected option
    this.skipStripeHiddenTarget.value = skipStripeSelectBox;

    if (skipStripeSelectBox === "false") {
      // it means change from pay-to-FF to pay-to-Stripe, then block selection of Plan, only Trial is allowed
      if (this.hasPaymentMethodTarget.value === "false") this.setDefaultOption();
    } else {
      // release plan selection restriction for non-Stripe
      this.enableElement(this.planSelectBoxTarget);
      if (this.existingStripeUser() && skipStripeSelectBox === "false") {
        // disable again if Admin chooses back to No (for user who has payment method)
        this.disableElement(this.currencySelectBoxTarget);
        this.expiryDateTarget.classList.add("hidden");
      } else {
        // release currency restriction for non-Stripe
        this.enableElement(this.currencySelectBoxTarget);
        // unhide expiry date field
        this.expiryDateTarget.classList.remove("hidden");
        // unhide fields for clinic subscriptions
        if (this.hasClinicSubscriptionTarget) {
          this.clinicFieldsTarget.classList.remove("hidden");
          this.enableElement(this.saveBtn);
        }
      }
    }
  }

  changeRole(event) {
    event.preventDefault();
    var roleOption = event.target.options[event.target.selectedIndex].value; // role id to be checked
    this.checkRoleEligibility(roleOption);
  }

  checkRoleEligibility(roleId) {
    var params = new URLSearchParams(new URL(window.location).search);
    var userId = params.get('owner');
    fetch(`/admin/users/${userId}/check_role?role_id=${roleId}`).then(response => {
      return response.json();
    }).then(data => {
      var saveClinicButton = document.querySelector("#saveClinic");
      if (data.can_manage) {
        this.enableElement(saveClinicButton);
        this.roleWarningTarget.classList.add("hidden");
      } else {
        this.disableElement(saveClinicButton);
        this.roleWarningTarget.classList.remove("hidden");
      }
    }).catch(error => {
      console.error('Error:', error);
      return false;
    });
  }

  existingStripeUser() {
    return (this.hasPaymentMethodTarget.value === "true" && this.currentSkipStripeTarget.value === "false");
  }

  validateDate(event) {
    event.preventDefault();

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    
    const dateInput = document.getElementById("subscription_cancel_at_3i");
    const monthInput = document.getElementById("subscription_cancel_at_2i");
    const yearInput = document.getElementById("subscription_cancel_at_1i");
    const selectedDate = new Date(parseInt(yearInput.value), parseInt(monthInput.value) - 1, parseInt(dateInput.value));

    if (selectedDate < tomorrow) {
      // shift to next month if selected date is smaller than tomorrow's date
      monthInput.value = tomorrow.getMonth() + 2 // because month is zero-based
      this.adjustMonthOptions(monthInput, tomorrow);
    } else if (yearInput.value <= tomorrow.getFullYear()) {
      this.adjustMonthOptions(monthInput, tomorrow);
    } else {
      this.showAllMonths(monthInput);
    }

    this.getDaysInMonth(dateInput, yearInput.value, monthInput.value);
  }

  adjustMonthOptions(monthInput, selectedDate) {
    const selectedMonth = selectedDate.getMonth() + 1; // month starts from 0
    const months = monthInput.options;

    for (let i = 0; i < months.length; i++) {
      if (months[i].value < selectedMonth) {
        months[i].classList.add("hidden");
      } else {
        months[i].classList.remove("hidden");
      }
    }
  }

  showAllMonths(monthInput) {
    const months = monthInput.options;
    for (let i = 0; i < monthInput.options.length; i++) {
      months[i].classList.remove("hidden");
    }
  }

  getDaysInMonth(dateInput, year, month) {
    const daysInMonth = new Date(parseInt(year), parseInt(month), 0).getDate();
    if (dateInput.value > daysInMonth) {
      dateInput.value = daysInMonth;
    }

    for (let i = 0; i <= dateInput.options.length - 1; i++) {
      if (dateInput.options[i].value > daysInMonth) {
        dateInput.options[i].classList.add("hidden");
      } else {
        dateInput.options[i].classList.remove("hidden");
      }
    }
  }

  findOptionIndex(selectBox, optionName) {
    for (var i = 0; i < selectBox.options.length-1; i++) {
      if (selectBox.options[i] == optionName) {
        return i;
      }
    }
  }

  setDefaultOption() {
    if (this.hasClinicSubscriptionTarget) {
      // means the UI is form for clinic subscriptions
      // no default option for clinic subscriptions as they can be empty
      // so hide all options and disable Save button
      this.clinicFieldsTarget.classList.add("hidden");
      this.disableElement(this.saveBtn);
    } else {
      // means the UI is just a form for user's subscription (legacy)
      this.planSelectBoxTarget.selectedIndex = this.findOptionIndex(this.planSelectBoxTarget, "trial");
      this.disableElement(this.planSelectBoxTarget);
      // default currency
      this.currencySelectBoxTarget.selectedIndex = this.findOptionIndex(this.currencySelectBoxTarget, "eur");
      this.disableElement(this.currencySelectBoxTarget);
      this.expiryDateTarget.classList.add("hidden");
    }
  }

  checkStorageFitness() {
    clearTimeout(this.maxStorageInputTimer);

    this.maxStorageInputTimer = setTimeout(() => {
      const maxStorage = this.maxStorageTarget.value;
      const objectId = this.objectIdTarget.value;
      const queryType = this.hasClinicSubscriptionTarget ? "clinic" : "";

      fetch(`/admin/subscriptions/check_storage?amount=${maxStorage}&id=${objectId}&query_type=${queryType}`)
        .then(response => response.json())
        .then(data => {
          if (!data.ok) {
            this.disableElement(this.saveBtn);
            this.checkStorageResultTarget.innerHTML = data.message || "An error occurred."; 
          } else {
            this.enableElement(this.saveBtn);
            this.checkStorageResultTarget.innerHTML = "";
          }
        })
        .catch(error => {
          console.error("Fetch Error:", error); // Debug fetch error
          this.checkStorageResultTarget.innerHTML = "Unable to validate storage. Please try again.";
        });
    }, 300);
  }

  handlePlanChange() {
    const featureLevel = this.planSelectBoxTarget.value;
    this.toggleAddonsVisibility(featureLevel);
    this.updateAddonsSelection(featureLevel);
    this.setMaxStorage(featureLevel);
  }

  setMaxStorage() {
    const featureLevel = this.planSelectBoxTarget.value;

    fetch(`/admin/subscriptions/max_space?feature_level=${featureLevel}`).then(response => {
      return response.json();
    }).then(data => {
      if (data.space == -1) {
        this.maxStorageTarget.value = 0.1;
      } else {
        this.maxStorageTarget.value = data.space
      }
    }).catch(error => {
      return error;
    });
  }
  
  toggleAddonsVisibility(featureLevel) {
    const excludedOptions = ["advanced", "starter"];
    if (excludedOptions.includes(featureLevel)) {
      this.addonsDivTarget.classList.add("hidden");
    } else {
      this.addonsDivTarget.classList.remove("hidden");
    }
  }

  updateAddonsSelection(featureLevel) {
    fetch(`/admin/subscriptions/get_addon_mappings?feature_level=${featureLevel}`).then(response => {
      return response.json();
    }).then(data => {
      if (data.ok) {
        const addons = data.modules || [];
        this.constructor.autoSelectedModules = data.modules || [];
        Array.from(this.addonsSelectTarget.options).forEach(option => {
          option.selected = addons.includes(option.value); // Select relevant options
        });
        $(this.addonsSelectTarget).trigger('change'); 
      }
      this.checkAddonsSelection()
    }).catch(error => {
      return error;
    });
  }

  checkAddonsSelection() {
    const autoSelectedModules = this.constructor.autoSelectedModules || [];
    const selectedOptions = Array.from(this.addonsSelectTarget.options)
      .filter(option => option.selected)
      .map(option => option.value);

    const isValidSelection = autoSelectedModules.every(module => selectedOptions.includes(module));

    const hasExtraSelections = selectedOptions.length >= autoSelectedModules.length;

    if (isValidSelection && hasExtraSelections) {
      this.enableElement(this.saveBtn);
    } else {
      this.disableElement(this.saveBtn);
    }
  }

  enableElement(element) {
    element.disabled = false;
    element.classList.remove("field-disabled");
  }

  disableElement(element) {
    element.disabled = true;
    element.classList.add("field-disabled");
  }
}